export const theme = {
    colors: {
        primary: '#DE6114',
        secondary: '#f2f2f2',
        success: '#28a745',
        danger: '#dc3545',
        warning: '#ffc107',
        info: '#17a2b8',
        light: '#CDCDCD',
        dark: '#000000',
        background: '#F9F9FB',
    },
};  