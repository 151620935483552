import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    margin: 0;
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  color: ${(props) => props.theme.colors.primary};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  height: 50px;

  @media (max-width: 480px) {
    height: 40px;
  }
`;

const Nav = styled.nav`
  a {
    color: ${(props) => props.theme.colors.primary};
    margin: 0 10px;
    font-weight: 500;
    padding-right: 30px;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.dark};
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      margin: 10px 0;
    }
  }
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  text-align: center;
  margin-top: 70px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 80px 10%;
    text-align: left;
  }
`;

const HeroContent = styled.div`
  max-width: 600px;

  h1 {
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: 700;
  }

  p {
    font-size: 1em;
    margin-bottom: 30px;
    line-height: 1.6;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 2.4em;
    }
  }
`;

const DownloadButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;

  a {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 0.9em;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const GooglePlayButton = styled.a`
  background-color: transparent;
  img {
    width: 150px;
  }
`;

const AppStoreButton = styled.a`
  background-color: transparent;
  img {
    width: 140px;
  }
`;

const HeroImage = styled.img`
  max-width: 90%;
  height: auto;
  margin-top: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-right: 25px;

  @media (min-width: 768px) {
    max-width: 15%;
    margin-top: 0;
  }
`;

const Section = styled.section`
  padding: 50px 20px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.dark};
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }

  p {
    font-size: 1em;
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    padding: 60px 20%;
    text-align: left;
    h2 {
      font-size: 2em;
    }
  }
`;

const Footer = styled.footer`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.dark};
  color: ${(props) => props.theme.colors.light};
  font-size: 0.9em;
  text-align: center;
  width: 100%;
`;

// Componentes para a seção de FAQ
const FAQSection = styled.section`
  padding: 50px 20px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.dark};
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;

  h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    text-align: center;
  }
`;

const FAQItem = styled.div<{ isOpen: boolean }>`
  margin-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
  padding: 10px 0;

  h3 {
    font-size: 1.1em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
  }

  p {
    font-size: 1em;
    line-height: 1.6;
    font-weight: 400;
    margin: 5px 0 0 0;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    color: ${(props) => props.theme.colors.dark};
  }
`;

const FAQ = () => {
  const faqs = [
    {
      question: "O que é a MATFY?",
      answer: "A Matfy é uma plataforma para compra de materiais de construção de forma ágil e prática, conectando clientes aos produtos de fornecedores locais com entregas rápidas."
    },
    {
      question: "Como funciona a Matfy?",
      answer: "A Matfy opera por meio de um aplicativo onde os clientes podem pesquisar produtos, realizar pedidos, acompanhar entregas e entrar em contato com os entregadores."
    },
    {
      question: "Como criar uma conta de acesso?",
      answer: "Para criar uma conta, basta acessar o aplicativo, clicar em 'Cadastro', inserir os dados solicitados e confirmar."
    },
    {
      question: "Como faço pra achar o produto que estou procurando?",
      answer: "Use nossa ferramenta de pesquisa de produtos. Basta digitar qualquer palavra-chave e clicar em buscar."
    },
    {
      question: "Como funciona o cupom de desconto?",
      answer: "O cupom de desconto é uma ferramenta especial que pode ser ativada quando a Matfy disponibiliza ofertas."
    },
    {
      question: "É possível acrescentar algum produto depois do pedido fechado?",
      answer: "Infelizmente, não. Uma vez que o pedido é fechado, não é possível fazer alterações. Nesse caso, é necessário realizar um novo pedido."
    },
    {
      question: "Existe um valor mínimo de pedido?",
      answer: "Não."
    },
    {
      question: "Depois do pedido concluído, tenho como falar diretamente com o entregador responsável pelo meu pedido?",
      answer: "Sim. Após a confirmação da compra, haverá uma opção de chat para falar diretamente com o entregador responsável pelo seu pedido."
    },
    {
      question: "Eu consigo enviar parte da compra para um endereço e outra parte para outro?",
      answer: "Não. Para enviar produtos para dois endereços diferentes, é necessário realizar duas compras separadas."
    },
    {
      question: "Posso efetuar a compra dos produtos no aplicativo e retirar em loja física?",
      answer: "Esse procedimento não é possível no momento."
    },
    {
      question: "Posso efetuar compras no aplicativo com CNPJ?",
      answer: "No momento, não."
    },
    {
      question: "Os preços do aplicativo são os mesmos apresentados em loja física?",
      answer: "Não. Os preços apresentados no aplicativo se referem exclusivamente às compras feitas pelo aplicativo."
    },
    {
      question: "Quais são os meios de pagamentos disponíveis?",
      answer: "Cartão de crédito, Cartão de débito, Pix e Transferências bancárias."
    },
    {
      question: "Posso efetuar pagamento em dois cartões diferentes?",
      answer: "Esse procedimento não é possível."
    },
    {
      question: "Quais são as formas de parcelamento?",
      answer: "Em até 8x, com parcela mínima de 100 reais."
    },
    {
      question: "Qual o prazo de aprovação da compra?",
      answer: "O prazo é de até 48 horas."
    },
    {
      question: "É possível alterar a forma de pagamento depois do pedido já ter sido realizado?",
      answer: "Não será possível esse procedimento."
    },
    {
      question: "Qual o procedimento se houver pagamento em duplicidade?",
      answer: "Entre em contato com a Matfy pelos canais de atendimento. Solicite o cancelamento do pagamento duplicado e, após análise, a Matfy solicitará à operadora do cartão que faça o estorno, se aplicável."
    },
    {
      question: "Em quanto tempo recebo meu pedido?",
      answer: "A previsão de entrega do seu pedido depende da demanda, mas garantimos a qualidade e agilidade. Após a finalização da compra, daremos a previsão de chegada. Você pode acompanhar em 'Meus pedidos'."
    },
    {
      question: "Como é calculado o frete do meu pedido?",
      answer: "Insira os produtos no carrinho e digite o CEP para o cálculo do frete."
    },
    {
      question: "É possível trocar de endereço depois do pedido fechado?",
      answer: "Infelizmente, não, a menos que você entre em contato conosco antes da saída do pedido e solicite a alteração do endereço de entrega."
    },
    {
      question: "Tenho como rastrear meu pedido?",
      answer: "Sim. No aplicativo, há a opção 'Meus pedidos' com as informações atualizadas sobre a situação da entrega."
    },
    {
      question: "Meu pedido está atrasado. O que devo fazer?",
      answer: "Entre em contato com a Matfy pelo link de atendimento. Faremos todo o possível para resolver a situação."
    },
    {
      question: "O que devo fazer se o meu pedido vier faltando algum item ou se algum item vier danificado ou defeituoso?",
      answer: "Tire uma foto do produto danificado ou defeituoso e nos envie uma mensagem explicando detalhadamente o ocorrido. Entraremos em contato rapidamente para resolver."
    },
    {
      question: "Como faço para cancelar meu pedido?",
      answer: "Entre em contato conosco pelo link disponível e cuidaremos do cancelamento para você."
    },
    {
      question: "Comprei, mas não deu certo. Posso trocar?",
      answer: "Você pode trocar seu pedido em até 30 dias sem custo adicional. Leia a política de troca no aplicativo."
    },
    {
      question: "Em caso de cancelamento, quais os prazos para o estorno?",
      answer: "A Matfy solicitará o estorno à administradora do cartão em até 72 horas, mas o tempo de processamento dependerá da política da administradora do cartão."
    },
    {
      question: "Quando cancelo o pedido é cobrada taxa de cancelamento?",
      answer: "Se o pedido já tiver sido aceito pelo estabelecimento, pode haver a cobrança do valor do produto e do frete."
    },
    {
      question: "Como os dados dos clientes são tratados pela Matfy?",
      answer: "Os dados dos clientes são tratados com segurança e de acordo com as normas de proteção de dados."
    },
    {
      question: "Eu posso solicitar a exclusão dos meus dados pessoais?",
      answer: "Sim, você pode solicitar a exclusão dos seus dados pessoais entrando em contato conosco."
    },
    {
      question: "Como faço se eu não encontrar a resposta da minha pergunta aqui?",
      answer: "Entre em contato conosco pelo link de atendimento. Estamos à disposição e será um prazer ajudar."
    }
  ];

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQSection>
      <h2>Perguntas Frequentes</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index} isOpen={openIndex === index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question} <span>{openIndex === index ? '-' : '+'}</span>
          </h3>
          <p>{faq.answer}</p>
        </FAQItem>
      ))}
    </FAQSection>
  );
};

const Home: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <Logo src={require("../../assets/images/logo.png")} alt="Matfy Logo" />
          <Nav>
            <a href="#about">Sobre o App</a>
            <a href="#pdv">PDV Inteligente</a>
          </Nav>
        </Header>

        <HeroSection>
          <HeroContent>
            <h1>App & PDV que facilitam a gestão do seu negócio</h1>
            <p>Descubra uma maneira eficiente e moderna de gerenciar seu PDV com nosso aplicativo.</p>
            <DownloadButtons>
              <GooglePlayButton href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" alt="Disponível no Google Play" />
              </GooglePlayButton>
              <AppStoreButton href="https://apps.apple.com/store" target="_blank" rel="noopener noreferrer">
                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83" alt="Baixar na App Store" />
              </AppStoreButton>
            </DownloadButtons>
          </HeroContent>
          <HeroImage src={require("../../assets/images/print_cell.jpeg")} alt="App Screenshot" />
          <HeroImage src={require("../../assets/images/print_cell2.jpeg")} alt="App Screenshot" />
        </HeroSection>

        <Section id="about">
          <h2>Sobre o App</h2>
          <p>A Matfy é uma empresa inovadora no mercado de materiais de construção. Com nosso diferencial único de entrega em até 2 horas, garantimos que seus projetos não parem por falta de materiais.</p>
        </Section>

        <Section id="pdv">
          <h2>PDV Inteligente</h2>
          <p>Nosso PDV foi desenvolvido para atender a todas as necessidades do varejo, com funcionalidades personalizáveis que se adaptam às operações diárias. Simplifique a gestão do seu negócio com o Matfy PDV.</p>
        </Section>

        <FAQ /> {/* Adiciona a seção de FAQ aqui */}

        <Footer>
          <p>© {new Date().getFullYear()} Matfy Online - Todos os direitos reservados.</p>
        </Footer>
      </Container>
    </>
  );
};

export default Home;
